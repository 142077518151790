import { gsap } from "gsap";

import { Scroll } from './_app/cuchillo/scroll/Scroll';

import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Basics, isDebug, isTouch } from './_app/cuchillo/core/Basics';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import { Interaction, MrInteraction } from './_app/cuchillo/core/Interaction';
import { GetBy } from "./_app/cuchillo/core/Element";
import { Metrics } from './_app/cuchillo/core/Metrics';
import { Statics } from './_app/cuchillo/utils/Statics';
import Keyboard from './_app/cuchillo/core/Keyboard';

import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';

import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import Page from './_app/cuchillo/pages/Page';
import Default from './pages/Default';
import Home from './pages/Home';
import About from './pages/About';
import Archive from './pages/Archive';
import Project from './pages/Project';
import ProjectInfo from './pages/ProjectInfo';
import Film from './pages/Film';
import Legal from './pages/Legal';
import Page404 from './pages/Page404';

import BG from './_app/cuchillo/layout/Background';
import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import Header from './layout/Header';
import Loading from './layout/Loading';
import Wrap from './layout/Wrap';

import { ControllerWindow } from './_app/cuchillo/windows/ControllerWindow';
import Win from './_app/cuchillo/windows/Window';
import Preloader from "./layout/Preloader";

import Language from "./_app/cuchillo/utils/Language";
import CMP from "./windows/Cmp";
import WebGLSketch from "./_app/cuchillo/3D/WebGLSketch";
import RedEye from "./3D/RedEye";
import Scene from "./3D/Scene";
import CircleSlider from "./3D/CircleSlider";
import FullSlider from "./3D/FullSlider";
import TexturesController from "./_app/cuchillo/3D/TexturesController";

export default class Main {
  static stats;
  static bgEye;
  static circleSlider;
  static fullSlider;
  static _isInit = false;;

  static init() {
    if(this._isInit) return;

    this._isInit = true;

    gsap.ticker.fps(60);

    Basics.id = "yza_v002"; // ID para cookies   
    Language.init();

    TexturesController.isPreload = true;
    [...GetBy.selector("[data-preload-texture]")].map(item => {
      TexturesController.load({src:item.dataset.src});
    });
    
    Header.init();
    
    WebGLSketch.init();
    WebGLSketch.start();

    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: true }) // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
    ControllerWindow.init(); // Control ventanas

    BG.init(CMS_COLORS); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
    InterfaceCanvas.init(); // Canvas de interface, se usa con Cursor
    CMP.init(); // Checkea y saca el aviso de cookies

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    //LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    LoaderController.onComplete = () => {
      Main.start();
      Main.setup()
    };

    Scene.init();
    Preloader.init(()=> LoaderController.init());
  }

  static start() {
    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
      this.doCuchilloInfo();
    } else {
      this.setWorker();
      this.doCuchilloInfo();
      gsap.ticker.add(() => { Main.loop(); });
    }

    this.bgEye = new RedEye({dom:GetBy.id("scene-three")});
    this.circleSlider = new CircleSlider(GetBy.id("circle-slider"));
    this.fullSlider = new FullSlider(GetBy.id("full-slider"));
    
    Scene.add(this.bgEye);
    Scene.add(this.fullSlider);
    Scene.add(this.circleSlider);

    this.bgEye.init();
  }

  static setup() {
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);
  }

  static setupEvents() {
    EventDispatcher.addEventListener(Page.ON_SHOW, () => {
      Loading.stop();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
      Loading.start();
    });

    EventDispatcher.addEventListener(Win.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Win.ON_SHOW, () => { Scroll.setEnabled(false); });
  }

  static resize() {
    Header.resize();
    InterfaceCanvas.resize();
    BG.resize();
    ControllerPage.resize();
    WebGLSketch.resize();

    //this.bgEye.resize();
    if(this.circleSlider) this.circleSlider.resize();
    if(this.fullSlider) this.fullSlider.resize();
    if(this.bgEye) this.bgEye.resize();
    
    if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static loop() {
    if (Scroll.isScrolling) Scroll.loop();

    ControllerPage.loop();

    this.bgEye.loop();
    this.circleSlider.loop();
    this.fullSlider.loop();

    BG.loop();
    InterfaceCanvas.loop();
    WebGLSketch.loop();
  }

  static loopDebug() {
    Statics.begin();
    this.loop();
    Statics.end();
  }

  static doCuchilloInfo() {
    // Constantes
    const TIMESTAMP = 'December 10th, 2024';
    const LOCATION = 'Bilbao';
    const COMPANY_DESC = 'Cuchillo© is a creative team specialized\nin building new perspectives and bold digital\nconcepts for brands to be timeless.';
    
    // Enlaces sociales
    const SOCIAL_LINKS = [
        'http://somoscuchillo.com',
        'https://www.instagram.com/_cuchillo',
        'https://twitter.com/somoscuchillo',
        'https://bsky.app/profile/mrcorrales.com',
        'https://twitter.com/mr__corrales'
    ];

    // Logo SVG
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 80">
        <path fill="black" d="M71.8 65.1V54.4c0-4.7-3.9-2.5-4.3-4.7-.1-.3-.1-.6-.1-.9V26.5l8 6.7 6.9-5.8L72 18.8 59 31.7v17.5c.2 2.1 4.6 1 4.4 5.2v15.3L74.6 79l10.8-10.6-8.2 1.2-5.4-4.5zm-58.9 0V54.4c0-4.7-3.9-2.5-4.3-4.7-.1-.3-.1-.6-.1-.9V26.5l8 6.7 6.9-5.8L13 18.8 0 31.7v17.5c.2 2.1 4.6 1 4.4 5.2v15.3L15.6 79l10.8-10.6-8.2 1.2-5.3-4.5zM132 54.4c.1-2.9-4.5-2.3-4.4-5.2V23.7l-5.8-4.9-7.6 7.5 4.9 4.1s.1 4 .1 17.9v.9c0 2.9 4.5 2.4 4.4 5.4l.1 19.4 5.8 5 7.2-7.2-4.8-4.1V54.4zm17.3.2c.2-3.8-4.4-2.9-4.4-5.6V1l-8.5 8.4v39.9c.1 2.4 4.4 1.4 4.4 5v18.6L149 79l6.3-6.3-6-5V54.6zM191.5 72l-6.9-5.7V54.7c0-2.8-3.9-2.5-3.9-5.1v-24l6.9 5.8v17.4c0 3.2 3.9 1.7 3.9 5.8V72zm4.6-22.6V26.9l-9.8-8.1-6.4 6.4s-6.7 6.7-7.5 7.4l-.1.1v16.2c0 2.9 3.8 2.6 3.9 5.7v16.2L186 79l14-14V54.6c0-4.1-3.7-2.7-3.9-5.2m-28.6 5.2c.1-3.4-4.4-2.8-4.4-4.7V.9l-8.5 8.5v39.9c.1 3.1 4.4.8 4.4 5.4V73l8.1 6.1 6.3-6.3-5.9-5V54.6zm-58-4.9v-.2-21l-9.1-6.2-6.2 6.2 6.9 5.8v14.9c0 .3 0 .6.1 1 0 2.6 4.3 1.4 4.4 4.5v24.6l8.5-8.5v-16c0-4.6-4.6-2.9-4.6-5.1m18.2-40.2l-5.8-5.9-6.1 5.9 6 5.9 5.9-5.9zm-42 .1v39.8c0 2.9 4.4 2.2 4.4 5.2v24.5l8.5-8.4V54.6c0-2.9-4.4-2.2-4.4-5V1.2l-8.5 8.4zM51 50c-.1-.3-.1-.6-.1-.9V18.8l-8.5 8.5s0 22.3.1 22.6c0 2.9 4.3 1.1 4.3 4.5V72L40 66.4v-12c0-3.2-4.2-2.2-4.4-5.2V18.8l-8.5 8.4v21.3c0 .3 0 .6.1.9.4 2.2 4.4 2.2 4.4 4.9v16.6l9.9 8.2 13.8-13.8v-11c0-3.4-3.7-2.5-4.3-4.3"></path>
    </svg>`;
    const svgUrl = `data:image/svg+xml;base64,${btoa(svg)}`;

    // Imprimir información en consola
    console.log(`%cProudly made in ${LOCATION} - ${TIMESTAMP}`, 'font-size:8px;');
    console.log('%c ', `
        font-size: 0; 
        padding: 60px 150px; 
        background: url(${svgUrl}) no-repeat center; 
        background-size: contain;
        margin-bottom: 10px;
    `);
    
    // Imprimir descripción
    console.log(`%c${COMPANY_DESC}`, '');
    
    // Imprimir enlaces sociales
    SOCIAL_LINKS.forEach((link, index) => {
        console.log(`${String.fromCharCode(9312 + index)} ${link}`);
    });
  }

  static setWorker() {
    if ('serviceWorker' in navigator && !isDebug) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function () { });
    }
  }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}
