import { ShaderMaterial, FrontSide, Vector4, Group, RGBA_ASTC_10x10_Format } from 'three';
import WebGLObject from '../_app/cuchillo/3D/WebGLObject';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { GetBy } from '../_app/cuchillo/core/Element';
import Image from '../_app/cuchillo/3D/Image';
import Keyboard, { KEYS } from '../_app/cuchillo/core/Keyboard';
import gsap, { Expo, Power0, Power2, Power4 } from 'gsap';
import FullSlider from './FullSlider';

export default class CircleSlider extends Group {
    static instance = null;
    static radius = 100;
    static rot = 0;
    static rotProgress = 0;
    static rotOffset = 0;
    static angle = 0;
    static scale = 1;
    static alpha = 1;
    static hideMod = 0;
    static maxRadius = 0;
    static hideRadius = 0;
    static minRadius = 0;
    static offsetScale = .3;
    static mode = "NORMAL";

    dom;
    data = [];
    total = 0;
    totalVisibles = 1;
    actual = 0;
    speed = 0;
    speedTo = 0;
    isShow = false;
                    
    static getInstance(data) {
        if (!CircleSlider.instance) {
            CircleSlider.instance = new CircleSlider(data);
        }
        return CircleSlider.instance;
    }

    get vuelta() { 
        return Math.floor((CircleSlider.rot / CircleSlider.angle) / this.totalVisibles);
    }
    
    get index() {  
        const vueltaActual = this.vuelta; // Evita recalcular `vuelta`
        return Math.round(CircleSlider.rot / CircleSlider.angle) - (this.totalVisibles * vueltaActual); 
    }

    constructor(__dom) {
        if (CircleSlider.instance) {
            return CircleSlider.instance;
        }

        super();

        CircleSlider.instance = this;

        this.dom = __dom;
        this.position.z = 1;
        this.resize();
        this.loadData();
    }

    loadData() {
       
        const items = [...GetBy.selector("li", this.dom)];
        this.total = items.length;
        this.totalVisibles = 1;//this.total;
        CircleSlider.angle = (Math.PI*2)/this.totalVisibles;
        
        for(let i = 0; i < this.total; i++) {
            const li = items[i];
            const item = new CircleSlider__Item(li, i);
           
            this.data.push({
                id: li.dataset.id,
                url: li.dataset.url,
                thumb: li.dataset.src,
                image: li.dataset.image,
                dom: li,
                item: item
            });

            this.add(item);
        }        
    }

    preshow() {
        gsap.to(this,{speed:100, speedTo:100, ease:Power2.easeIn, duration:2, delay:0});
        
        let delay = 500
        setTimeout(()=> { this.isShow = true; }, 500);

        for(let i = 0; i<5; i++) {
            delay = delay + 200;
            setTimeout(()=> {
                this.showItem();
            }, delay);
        }
        
        setTimeout(()=> {
            this.showAll();
        }, delay + 400);
    }

    show() {
        if(!this.isShow) {
            this.preshow();
        }

        gsap.killTweensOf(this.position)
        gsap.to(this.position,{z:1, duration:2, ease:Expo.easeOut,
            onStart:()=> {},
            onComplete:()=> {}});
    }

    hide() {
        gsap.killTweensOf(this.position)
        gsap.to(this.position,{z:1000, duration:.5, ease:Power2.easeIn,
            onStart:()=> {},
            onComplete:()=> {}});
    }

    showItem() {
        this.totalVisibles = Math.min(this.total, this.totalVisibles + 1);
        CircleSlider.angle = (Math.PI*2)/Math.max(this.totalVisibles, 6);
    }

    showAll() {
        for(let i = 0; i<this.total - this.totalVisibles; i++) {
            setTimeout(()=> {
                this.showItem();
                
                this.speed = 100 + 5 * i;
                this.speedTo = 0;
            }, i * 100);
        }

        setTimeout(()=> {
            const index = this.index;           
            
            CircleSlider.mode = "NONE";
            FullSlider.getInstance().scroller.directGoto(index);

            gsap.to(CircleSlider,{rotProgress:Math.round(CircleSlider.rot/CircleSlider.angle)*CircleSlider.angle,duration:.4, ease:Power2.easeInOut,
                onStart:()=> {
                    FullSlider.getInstance().scroller.goto(index);
                },
                onComplete:()=> {
                    this.actual = index + this.totalVisibles * this.vuelta                    
                    CircleSlider.mode = "FIXED";
                }})            
        }, 100 * this.total)
    }
    
    init() {
        this.resize();
    }

    next() {
        this.actual++;
    }

    prev() {
        this.actual--;
    }

    goto(__n) {
        this.actual = Math.round(__n)
    }

    gotoIndex(__n, __direction = 0) {
        const actual = this.index;
        const vuelta = this.vuelta;

        if(__n === this.index) return;

        if(__direction === 1) {
            this.actual = __n < actual? __n + this.totalVisibles * (this.vuelta + 1) : __n + this.totalVisibles * this.vuelta;
        } else {
            this.actual = __n > actual? __n + this.totalVisibles * (this.vuelta - 1) : __n + this.totalVisibles * this.vuelta;
        }
    }

    loop() {
        const nVisibles = Math.max(7, (this.totalVisibles-1));
        const pVisibles = nVisibles/(this.total-1);
        const circleAngle = CircleSlider.angle;

        if(CircleSlider.mode == "NORMAL" || CircleSlider.mode == "HIDE") {
            this.speed += (this.speedTo - this.speed) * 0.1;
            CircleSlider.rotProgress += this.speed / 1000;
        } else if(CircleSlider.mode == "FIXED") {
            CircleSlider.rotProgress += (circleAngle * this.actual - CircleSlider.rotProgress) * 0.25;
        }

        CircleSlider.rot = CircleSlider.rotProgress + CircleSlider.rotOffset;
        CircleSlider.scale = 1 - CircleSlider.offsetScale * pVisibles;
        CircleSlider.radius += (CircleSlider.maxRadius * pVisibles + Math.abs(FullSlider.getInstance().scroller.speed / 10) - CircleSlider.radius + CircleSlider.hideMod) * 0.1;     
        
        for(let i = 0; i < this.total; i++) {
            const item = this.data[i].item;
            item.isVisible = i < this.totalVisibles && this.isShow;

            if(!item.visible)  item.directAgle = circleAngle;
            else               item.newAgle = circleAngle;

            item.loop();
        }
    }

    resize() {
        CircleSlider.hideRadius = Math.max(Metrics.CENTER_X , Metrics.CENTER_Y) * 1.2;
        CircleSlider.maxRadius = Math.min(Metrics.CENTER_X , Metrics.CENTER_Y) * .8;
        CircleSlider.minRadius = Math.min(Metrics.CENTER_X , Metrics.CENTER_Y) * .05;
    }
}

class CircleSlider__Item extends Group {
    image;
    index = 0;
    _rot = 0;
    _radius = 0;
    _fixedRot = 0;
    _newFixedRot = 0;
    _isVisible = false;

    _timers = {
        visible: 0
    }

    get rot() { return this._rot };
    set rot(_rot) {
        const rotTo = _rot - this._fixedRot;
        this._rot = rotTo;
        this.rotation.z = rotTo;
        this.image.rotation.z = - rotTo;
    }

    get radius() { return this._radius };
    set radius(_radius) {
        this._radius = _radius;
        this.image.position.y = this._radius;
    }

    set newAgle(__newAgle) {
        this._newFixedRot = __newAgle * this.index;
    }

    set directAgle(__newAgle) {
        this._fixedRot =
            this._newFixedRot = __newAgle * this.index;
    }

    get isVisible() { return this._isVisible; }
    set isVisible(__visible) {
        if(__visible == this._isVisible) return;
        
        this._isVisible = __visible;
        
        clearTimeout(this._timers.visible)
        this._timers.visible = setTimeout(()=> {
            this.visible = this._isVisible;
        }, this._isVisible? 30 : 150);
    }


    constructor(dom, __index) {
        super();
        this.index = __index;
        
        this.visible = false;

        this._newFixedRot =
            this._fixedRot = CircleSlider.angle * __index;
        
        this.image = new Image({dom:dom, hasFreePosition:true});

        this.rot = CircleSlider.rot;
        this.radius = CircleSlider.radius;

        this.add(this.image);
    }

    loop() {
        this.rot = CircleSlider.rot;
        this.radius = CircleSlider.radius;
        this._fixedRot = Maths.lerp(this._newFixedRot, this._fixedRot, .9);
        this.image.scale.set(CircleSlider.scale, CircleSlider.scale, CircleSlider.scale);
        this.image.material.uniforms.opacity.value = CircleSlider.alpha;
    }
}
